import { theme } from "@product/scmp-sdk";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { section as sectionData } from "scmp-app/data/section";

import type { SubSectionWidget } from "./types";

export const getIncludeSectionFeatureMap = (entityId: string) => {
  const widgets = getSubSectionWidgets(entityId);
  return {
    includeComment: widgets.includes("comment"),
    includeMultimedia: widgets.includes("multimedia"),
    includePostMagazine: widgets.includes("post-magazine"),
    includeTrendingTopics: widgets.includes("trending-topics"),
  };
};

export const getSubSectionWidgets = (entityId: string): SubSectionWidget[] => {
  switch (entityId) {
    case sectionData.lifestyle.artsAndCulture.entityId:
    case sectionData.lifestyle.familyAndRelationships.entityId:
    case sectionData.lifestyle.healthAndWellness.entityId:
    case sectionData.lifestyle.travelAndLeisure.entityId:
    case sectionData.news.peopleAndCulture.chinaPersonalities.entityId:
    case sectionData.news.peopleAndCulture.environment.entityId:
    case sectionData.news.peopleAndCulture.genderAndDiversity.entityId:
    case sectionData.news.peopleAndCulture.socialWelfare.entityId:
    case sectionData.news.peopleAndCulture.trendingInChina.entityId:
      return ["post-magazine", "comment", "multimedia", "trending-topics"];
    default:
      return ["comment", "trending-topics", "multimedia"];
  }
};

export const getSubSectionAdsConfigs = (sectionId: string, adZone = "default") => {
  const defaultAdSlotProps: Partial<AdSlotProps> = {
    targeting: {
      paid: TargetingPaidType.Free,
      scsid: [sectionId],
    },
    withBackground: true,
    withLabel: true,
    withSticky: true,
    zone: adZone,
  };

  const configs: Array<[AdSlotProps, AdSlotProps]> = [
    // AD2 = FirstAds in grid layout
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec1",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], [300, 600], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_banner1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD3
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD4
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3a",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], [300, 600], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
    // AD5
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec2",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
  ];

  return configs;
};
